import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37')
];

export const server_loads = [0,3,6,7];

export const dictionary = {
		"/": [~10],
		"/(auth)/auth": [11,[2]],
		"/(dashboard)/dashboard": [~16,[3]],
		"/(dashboard)/dashboard/account": [~17,[3]],
		"/(dashboard)/dashboard/feature-request": [~18,[3]],
		"/(dashboard)/dashboard/finances": [~19,[3]],
		"/(dashboard)/dashboard/requests/[requestId]": [~20,[3]],
		"/(dashboard)/dashboard/services": [~21,[3]],
		"/(dashboard)/dashboard/support": [~22,[3]],
		"/documents/notes/[requestId]": [~36],
		"/(public)/downloads/notes/[requestId]": [~30,[4]],
		"/(auth)/login/confirm": [~12,[2]],
		"/(auth)/login/hidden": [~13,[2]],
		"/(auth)/logout": [14,[2]],
		"/marketing": [37],
		"/(auth)/me": [15,[2]],
		"/(public)/(legal)/privacy-policy": [23,[4,5]],
		"/(public)/requests/[requestId]": [~31,[4]],
		"/(public)/requests/[requestId]/payment": [~32,[4]],
		"/(public)/requests/[requestId]/payment/callback": [~33,[4]],
		"/(public)/requests/[requestId]/payment/success": [~34,[4]],
		"/(public)/setup/profile": [~35,[4]],
		"/(public)/(legal)/terms": [24,[4,5]],
		"/(public)/[username]": [~25,[4,6]],
		"/(public)/[username]/[serviceId]/contact": [~26,[4,6,7]],
		"/(public)/[username]/[serviceId]/medical": [~27,[4,6,7]],
		"/(public)/[username]/[serviceId]/payment": [~28,[4,6,7]],
		"/(public)/[username]/[serviceId]/payment/callback": [~29,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';